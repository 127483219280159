import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import website from '../../config/website'
import { Layout, Footer, SEO } from '../components'
import { theme, prism } from '../styles'

export default function Post({ data, location }) {
  const { post, nexts } = data
  const nextEdge = nexts.edges.find(e => e.node.fields.slug === post.fields.slug)
  const next = nextEdge.previous || nextEdge.next

  return (
    <Layout logo customSEO>
      <SEO title={`${post.frontmatter.title}`} pathname={location.pathname} desc={post.excerpt} node={post} article />
      <header css={styles.header}>
        <div>
          <div css={styles.headline}>
            <span>{post.frontmatter.date}</span>
            <span css={styles.separator}>•</span>
            <span>{post.timeToRead} min read</span>
          </div>
          <h1 css={styles.title}>{post.frontmatter.title}</h1>
        </div>
      </header>
      <main id={website.skipNavId} css={styles.main}>
        <div css={prism} dangerouslySetInnerHTML={{ __html: post.html }} />
      </main>
      <Footer>
        {next && (
          <>
            <h4 css={styles.readNextPretitle}>Read Next</h4>
            <h3 css={styles.readNextTitle}>
              <Link to={next.fields.slug}>{next.frontmatter.title}</Link>
            </h3>
            <p css={styles.readNextExcerpt}>
              {next.excerpt} <Link to={next.fields.slug}>Continue →</Link>
            </p>
          </>
        )}
      </Footer>
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const styles = {
  headline: {
    fontFamily: theme.fonts.mono,
    fontSize: 15,
    color: '#444',
    marginBottom: '0.5rem',
    a: {
      fontStyle: 'normal',
      fontWeight: 'normal'
    }
  },
  header: {
    maxWidth: '740px',
    margin: '0 auto',
    padding: '0 1.25rem',
    paddingTop: '4rem',
    marginBottom: '2.2rem',
    [theme.media.desktop]: {
      padding: '0 2rem',
      paddingTop: '10rem'
    }
  },
  title: {
    paddingBottom: '1rem',
    lineHeight: '1.33',
    borderBottom: '1px dashed #ccc'
  },
  separator: {
    opacity: 0.8,
    margin: '0 20px'
  },
  main: {
    maxWidth: '760px',
    margin: '0 auto',
    padding: '0 1.25rem',
    paddingBottom: '2rem',
    [theme.media.desktop]: {
      padding: '0 2rem',
      paddingBottom: '2rem'
    },
    'p, ul, ol, h1, h2, h3, h4, hr': {
      maxWidth: 'calc(740px - 4rem)',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    h2: {
      marginTop: '3rem',
      marginBottom: '1rem'
    },
    h3: {
      marginTop: '2.8rem',
      marginBottom: '1rem'
    },
    h4: {
      marginTop: '2.2rem',
      marginBottom: '0.7rem'
    },
    li: {
      marginLeft: '1.6rem',
      paddingLeft: '0.5rem'
    },
    hr: {
      maxWidth: '620px',
      background: 'rgba(0,0,0,.08)'
    },
    blockquote: {
      marginLeft: 'auto',
      marginRight: 'auto',
      borderLeft: `2px solid ${theme.colors.primary}`,
      padding: '0 1rem',
      [theme.media.desktop]: {
        padding: '0 1.95rem'
      },
      p: {
        fontStyle: 'italic'
      }
    },
    'a.gatsby-resp-image-link': {
      marginBottom: '2rem'
    },
    'div[data-language]': {
      marginBottom: '1.5rem',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  readNextTitle: {
    marginBottom: '1rem',
    a: {
      color: 'black'
    }
  },
  readNextExcerpt: {
    marginBottom: '0'
  }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $kind: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
    nexts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { kind: { eq: $kind } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
        }
        next {
          timeToRead
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
        previous {
          timeToRead
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
